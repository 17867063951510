<div class="home-slider-area">
    <div class="home-slider owl-theme owl-carousel">
      <div class="slider-item slider-bg-one" style="background-image: url('../assets/img/home-slider/A.png');">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <div class="slider-text">
                                    <h1>Nuestra diferencia está en la atención y el servicio</h1>
                                    <p>ASESORIA GRATUITA</p>
                                    <a  (click)="toContact()" >CONTÁCTANOS</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
<!--         <div class="slider-item slider-bg-one" style="background-image: url('../assets/img/home-slider/A.png');">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <div class="slider-text">
                                    <h1>SLIDER2</h1>
                                    <p>ASESORIA GRATUITA</p>
                                    <a  (click)="toContact()" >CONTÁCTANOS</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        </div>
    <div class="slider-counter" style=""></div>
</div>

<section  id="nosotros" class="nos-area nos-area-three pb-70">
</section>

<div class="clearfix" style="height: 30px"></div>
<!-- NOSOTROS -->
<section class="nos-area nos-area-three pb-70">
    <div>
        <div class="container-fluid">
            <h2 id="nos-title">NOSOTROS</h2> 
            <div class="row">
                <div class="col-lg-6">
                    <div class="nos-item">
                        <div class="nos-right">                     
                            <p>La Notaría Pública 145 de la ciudad de Morelia a cargo del Lic. Alfonso Carlos Vaca Tavera, está conformada por un equipo de profesionales con altos estándares de calidad y servicio.</p>

                            <p> El Notario como un profesional del Derecho, investido de la fe pública por el Estado, garantiza la legitimidad de los actos en los que interviene y proporciona a los ciudadanos seguridad jurídica.</p>
                            <div class="clearfix" style="height: 40px"></div>
                            <div id="container">
                                <button (click)="toContact()" class="btn btnslide">
                                    <span class="circle">
                                        <span class="icon arrow"></span>
                                    </span>
                                    <span class="button-text">CONTÁCTANOS</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="assets/img/nosotrosimag.png" alt="Help" id="nos-img">
                </div>
            </div>
        </div>
    </div>
</section>

<section id="servicios" class="services-area pt-100 pb-70">
    <div class="container"> 
        <div class="section-title">
            <h2 id="services-title">SERVICIOS</h2> 
        </div>
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">                   
                    <li *ngFor="let tab of tabs" class="nav-item">
                        <a class="{{tab.class}}" id="pills-home-tab" data-toggle="pill" href="#pills-home" data-rel="{{tab.datarel}}" role="tab" aria-controls="pills-home" >{{tab.title}}</a>    
                    </li>
                </ul>
                <div class="tab-content " id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="row" style="">
                            <!-- Testamento -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-1')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>TESTAMENTO</h3>
                                    <p>Voluntad manifestada para disponer de bienes, derechos y obligaciones al fallecer</p>
                                </div>
                            </div>
                            <!-- Compra venta -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-2')">
                                    <div class="service-icon">
                                     <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                     <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>COMPRA-VENTA</h3>
                                    <p>Transmisión de un bien o un derecho a cambio de pago</p>
                                </div>
                            </div>
                            <!-- REVOCACIÓN DE TESTAMENTO -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-3')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>REVOCACIÓN DE TESTAMENTO</h3>
                                    <p></p>
                                </div>
                            </div>
                            <!-- COMPRA VENTA CON RESERVA DE DOMINIO  -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-4')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>COMPRA VENTA CON RESERVA DE DOMINIO</h3>
                                    <p>Transmisión de un bien o un derecho a cambio de pago.</p>
                                </div>
                            </div>
                            <!-- DONACIÓN DE TERRENO -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-5')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>DONACIÓN DE TERRENO</h3>
                                    <p>Contrato en el que el donante transmite a título gratuito generalmente la propiedad de un bien mueble o inmueble</p>
                                </div>
                            </div>
                            <!-- DONACIÓN DE DEPARTAMENTO EN CONDOMINIO -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-6')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>DONACIÓN DE DEPARTAMENTO EN CONDOMINIO</h3>
                                    <p>Contrato en el que el donante transmite a título gratuito generalmente la propiedad de un bien mueble o inmueble</p>
                                </div>
                            </div>
                            <!-- DONACIÓN DE CASA HABITACIÓN -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-7')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>DONACIÓN DE CASA HABITACIÓN</h3>
                                    <p>Contrato en el que el donante transmite a título gratuito generalmente la propiedad de un bien mueble o inmueble</p>
                                </div>
                            </div>
                            <!-- DONACIÓN DE LOCAL COMERCIAL -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-8')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>DONACIÓN DE LOCAL COMERCIAL</h3>
                                    <p>Contrato en el que el donante transmite a título gratuito generalmente la propiedad de un bien mueble o inmueble</p>
                                </div>
                            </div>
                            <!-- CANCELACIÓN DE HIPOTECA INFONAVIT Y BANCARIA -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item" (click)="openModal('custom-modal-9')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>CANCELACIÓN DE HIPOTECA INFONAVIT Y BANCARIA</h3>
                                    <p>Contrato en el que el donante transmite a título gratuito generalmente la propiedad de un bien mueble o inmueble</p>
                                </div>
                            </div>
                            <!-- CONSTITUCIÓN DE SOCIEDADES -->
                            <div class="col-sm-6 col-lg-3 all corpo">
                                <div class="service-item" (click)="openModal('custom-modal-10')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>CONSTITUCIÓN DE SOCIEDADES</h3>
                                    <p></p>
                                </div>
                            </div>
                            <!-- RATIFICACIÓN DE FIRMAS -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-11')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>RATIFICACIÓN DE FIRMAS</h3>
                                    <p>Reconocimiento del contenido y firma en un documento</p>
                                </div>
                            </div>
                            <!-- PROTOCOLIZACIÓN DE ACTAS -->
                            <div class="col-sm-6 col-lg-3 all corpo">
                                <div class="service-item" (click)="openModal('custom-modal-12')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>PROTOCOLIZACIÓN DE ACTAS</h3>
                                    <p>Formalización de acuerdos tomados en asambleas de accionistas, socios y/o sesiones de consejo de administración.</p>
                                </div>
                            </div>
                            <!-- PODERES -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-13')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>PODERES.</h3>
                                    <p>Documento que faculta a realizar actos jurídicos a nombre de otros.</p>
                                </div>
                            </div>
                            <!-- REVOCACIÓN DE PODER -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-14')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>REVOCACIÓN DE PODER</h3>
                                    <p>Acto jurídico que deja sin efectos poderes otorgados con anterioridad</p>
                                </div>
                            </div>
                            <!-- FE DE DERECHOS -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-15')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>FE DE HECHOS</h3>
                                    <p>Hechos apreciados objetivamente por el Notario</p>
                                </div>
                            </div>
                            <!--COPIAS CERTIFICADAS -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-16')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>COPIAS CERTIFICADAS</h3>
                                    <p>Constatar en copia simple concordancia con el documento original del que se tomó</p>
                                </div>
                            </div>
                            <!-- CARTA PERMISO -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item" (click)="openModal('custom-modal-17')">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>CARTA PERMISO</h3>
                                    <p></p>
                                </div>
                            </div>
                            <!-- ADJUDICACIONES POR REMATE JUDICIAL-->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>ADJUDICACIONES POR REMATE JUDICIAL</h3>
                                    <p>Formalización de adquisición de bienes (muebles o inmuebles) objeto de remate</p>
                                </div>
                            </div>
                            <!-- FIDEICOMISOS -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>FIDEICOMISOS</h3>
                                    <p>Transmisión de una propiedad de un bien a un fiduciario</p>
                                </div>
                            </div>
                            <!-- DACIONES DE PAGO -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>DACIONES DE PAGO</h3>
                                    <p>Transmisión de una propiedad como pago de una deuda</p>

                                 </div>
                            </div>
                            <!-- TRANSMISIÓN DE PROPIEDAD EN EJECUCIÓN DE FIDEICOMISO -->
                            <div class="col-sm-6 col-lg-3 all civil">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>TRANSMISIÓN DE PROPIEDAD EN EJECUCIÓN DE FIDEICOMISO</h3>
                                    <p>Transmisión de una propiedad de un bien a un fiduciario</p>
                                </div>
                            </div>
                            <!-- APORTACIONES A SOCIEDADES -->
                            <div class="col-sm-6 col-lg-3 all corpo">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>APORTACIONES A SOCIEDADES</h3>
                                    <p></p>
                                </div>
                            </div>
                            <!-- HIPOTECA -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>HIPOTECA</h3>
                                    <p>Contrato donde se establece la obligación del deudor garantizado con el gravamen sobre el inmueble de su propiedad</p>
                                </div>
                            </div>
                            <!-- CANCELACIÓN DE HIPOTECA (GRAVAMEN) -->
                            <div class="col-sm-6 col-lg-3 all inmob">
                                <div class="service-item">
                                    <div class="service-icon">
                                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                                    </div>
                                    <h3>CANCELACIÓN DE HIPOTECA (GRAVAMEN)</h3>
                                    <p>Voluntad del acreedor hipotecario para liberar una hipoteca en virtud de que ya se cumplió la obligación</p>
                                </div>
                            </div>
                        </div>
                  </div>
                </div>
            </div>
        </div>
        <div id="container" style="text-align: center;">
            <button class="btn btnslide" id="loadMore">
                <span class="circle">
                    <span class="icon arrow"></span>
                </span>
                <span class="button-text"> VER MÁS </span>
            </button>
       </div>
    </div>
</section>

<section class="image-parallax" id="parallax1" style="background: url(assets/img/parallax1.png) no-repeat center center;">   
</section>
<div class="clearfix" style="height: 40px"></div>
<section class="expertise-area expertise-area-two" style="background: transparent;">
    <div class="container">

        <div class="row" style="padding: 0px 12% 0px 12%;">
            <!-- ADJUDICACIONES POR REMATE JUDICIAL-->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-18')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>ADJUDICACIONES POR REMATE JUDICIAL</h3>
                    <p>Formalización de adquisición de bienes (muebles o inmuebles) objeto de remate</p>
                </div>
            </div>
            <!-- FIDEICOMISOS -->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-19')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>FIDEICOMISOS</h3>
                    <p>Transmisión de una propiedad de un bien a un fiduciario</p>
                </div>
            </div>
            <!-- DACIONES DE PAGO -->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-20')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>DACIONES DE PAGO</h3>
                    <p></p>

                 </div>
            </div>
            <!-- TRANSMISIÓN DE PROPIEDAD EN EJECUCIÓN DE FIDEICOMISO -->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-21')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>TRANSMISIÓN DE PROPIEDAD EN EJECUCIÓN DE FIDEICOMISO</h3>
                    <p>Transmisión de una propiedad de un bien a un fiduciario</p>
                </div>
            </div>
            <!-- APORTACIONES A SOCIEDADES -->
            <div class="col-sm-6 col-lg-3 ">
                <div class="service-item" (click)="openModal('custom-modal-22')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>APORTACIONES A SOCIEDADES</h3>
                    <p></p>
                </div>
            </div>
            <!-- HIPOTECA -->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-23')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>HIPOTECA</h3>
                    <p>Contrato donde se establece la obligación del deudor garantizado con el gravamen sobre el inmueble de su propiedad</p>
                </div>
            </div>
            <!-- CANCELACIÓN DE HIPOTECA (GRAVAMEN) -->
            <div class="col-sm-6 col-lg-3">
                <div class="service-item" (click)="openModal('custom-modal-24')">
                    <div class="service-icon">
                        <img class="icon-yellow" src="assets/img/Icon_check_outline@2x.png" alt="Shape">
                        <img class="icon-black" src="assets/img/Icon_check_outline@3x.png" alt="Shape">
                    </div>
                    <h3>CANCELACIÓN DE HIPOTECA (GRAVAMEN)</h3>
                    <p>Voluntad del acreedor hipotecario para liberar una hipoteca en virtud de que ya se cumplió la obligación</p>
                </div>
            </div>
        </div>

    </div>
</section>

<div class="clearfix" style="height: 40px"></div>

<section id="contacto"  class="contact pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span style="">CONTACTO</span>
        </div>
        <div class="section-subtitle" style="">
            <span style="">Asesoría Gratuita</span>
        </div>
        <div class="row">
            <div class="col-lg-5 card-body contact text-center h-100 white-text">
                <div class="contact-icon contact-icon--flexbox">
                    <img  src="assets/img/hora-icon.png" alt="" />
                    <span>Lunes a Viernes<br>09:30 a.m. a 18:00 p.m.</span>
                </div>
                <div class="contact-icon contact-icon--flexbox">
                    <img  src="assets/img/icon-location.png" alt="" />
                    <span>Juan Sebastian Bach #237 Col. La Loma C.P. 58290.<br>
                        Morelia, Michoacán, México</span>
                </div>
                <hr class="hr-light my-4">
                <ul class="list-inline text-center list-unstyled">
                    <li class="list-inline-item">
                        <a class="p-2 fa-lg tw-ic">
                            <img  src="assets/img/Waze.png" alt="" />
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://api.whatsapp.com/send?phone=524433876089&text=Hola%2C%20necesito%20informaci%C3%B3n%2C%20gracias" class="p-2 fa-lg li-ic">
                            <img  src="assets/img/Whats.png" alt="" />
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="p-2 fa-lg ins-ic">
                           <img  src="assets/img/Face.png" alt="" />
                        </a>
                    </li>
                    </ul>
            </div>
            <div class="col-lg-2 card-body contact text-center h-100 white-text"></div>
            <div class="col-lg-5 card-body contact text-center h-100 white-text">
                <div class="contact-icon contact-icon--flexbox">
                    <img  src="assets/img/icon-call.png" alt="" />
                    <span>+52 (443) 387 60 89</span>
                </div>
                <div class="contact-icon contact-icon--flexbox">
                    <img  src="assets/img/icon-call.png" alt="" />
                    <span>+52 (443) 387 67 06</span>
                </div>
                <div class="contact-icon contact-icon--flexbox">
                    <img  src="assets/img/icon-email.png" alt="" />
                    <span>alfonsocvaca@notario145.com.mx</span>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="image-parallax" id="parallax2" style="background: url(assets/img/parallax2.png) no-repeat center center ;">   
</section>
<section class="map" style="line-height: 0;">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.7617386457796!2d-101.18813534907831!3d19.68016783775106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0da635868199%3A0x63694e3513121c5d!2sJuan%20Sebasti%C3%A1n%20Bach%20237%2C%20La%20Loma%2C%2058099%20Morelia%2C%20Mich.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sus!4v1597271593191!5m2!1ses!2sus" width="100%" height="450" frameborder="0" style="border:0;line-height: 0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</section>


<jw-modal id="custom-modal-1" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center" style="margin-left: 0 !important">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                            <span class="modal-title">TESTAMENTO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/testamento2.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>   
                    </div>
                </div>
                
                <button (click)="closeModal('custom-modal-1');" class="close-modalb">                     
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">Requisitos:
                        </span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                         <ul class="list-group">
                            <li class="list-group-item">1. Identificación oficial vigente de los comparecientes</li>
                            <li class="list-group-item">2. Acta de Matrimonio del Testador</li>
                            <li class="list-group-item">3. CURP</li>
                            <li class="list-group-item">4. Tres testigos mayores de edad con Identificación oficial vigente; que no sean herederos o familiares del testador</li>
                        </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-2" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                            <span class="modal-title">COMPRA-VENTA</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-2');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">Requisitos:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                            <li class="list-group-item">1. Escritura (antecedente de propiedad)</li>
                            <li class="list-group-item">2. Si la escritura ampara sólo terreno: <br>
                                a) Licencia de construcción; <br>
                                b) Constancia del Número oficial;<br>
                                c) Aviso de terminación de obra <br>
                            </li>
                            <li class="list-group-item">3. Boleta predial pagada vigente, a nombre del propietario.</li>
                            <li class="list-group-item">4. Boleta de agua y luz pagada vigente</li>
                            <li class="list-group-item">5. Acta de matrimonio ó divorcio del vendedor</li>
                            <li class="list-group-item">6. Llenar formato de datos generales de cada una de las personas que intervengan en la operación</li>
                            <li class="list-group-item">7. Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                            <li class="list-group-item">8. Cédula del registro federal de contribuyentes de las partes. Constancia de situación fiscal)</li>
                            <li class="list-group-item">9. CURP</li>
                            <li class="list-group-item">10. Si alguna de las personas que interviene en las operaciones es extranjera,deberá acreditar su legal estancia con forma migratoria o tarjeta de residencia</li>
                            <li class="list-group-item">11. En caso de acudir en representación de una persona física o jurídica, presentar poder notarial</li>
                            <li class="list-group-item">12. Acta de Nacimiento de las partes</li>
                            <li class="list-group-item">13. En caso de proceder, presentar recibos de luz, teléfono, o estados de cuentas bancarias o de comercios, INE con el domicilio del inmueble que se va a vender con el fin de exentar el impuesto sobre la renta</li>
                            <li class="list-group-item">14. En caso de estar sujeta la propiedad a régimen de propiedad en condominio se deberá presentar:
                                Escritura de constitución del régimen de propiedad en condominio;<br>
                                Reglamento del condominio;<br>
                                Constancia de no adeudo de cuotas<br>
                            </li>
                            <li class="list-group-item">15. Comprobante de domicilio de las partes (mínimo dos meses anteriores).</li>
                        </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-3" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                    <span class="modal-title">REVOCACIÓN DE TESTAMENTO</span>
                                            </div>
                                            <div class="col-md-4 ml-auto formatb">
                                                <span>DESCARGAR FORMATO 
                                                    <a target="_blank" href="assets/files/testamento2.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <button (click)="closeModal('custom-modal-3');" class="close-modalb"> 

                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="container">
                                        <div class="row">
                                            <span class="modal-req">REQUISITOS:</span>
                                        </div>
                                        <div class="row">
                                            <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                                        </div>
                                        <div class="row">
                                            <ul class="list-group">
                    <li class="list-group-item">1.- Escritura original del testamento vigente</li>
                    <li class="list-group-item">2.- Identificación oficial vigente de las partes comparecientes (credencial de elector, pasaporte, cédula profesional)</li>
                    <li class="list-group-item">3.- CURP del Testador</li>
                    <li class="list-group-item">4.- Acta de matrimonio del Testador</li>
                    <li class="list-group-item">5.- Tres testigos mayores de edad con Identificación Oficial; que no sean herederos o familiares del testador.</li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-4" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
            <span class="modal-title">
        COMPRA VENTA CON RESERVA DE DOMINIO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-4');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Último pago de predial</li>
                    <li class="list-group-item">2.- Último pago de agua y luz</li>
                    <li class="list-group-item">3.- Título o escritura del inmueble</li>
                    <li class="list-group-item">4.- Acta de matrimonio ó divorcio</li>
                    <li class="list-group-item">5.-CURP</li>
                    <li class="list-group-item">6.- Constancia de situación Fiscal</li>
                    <li class="list-group-item">7.- Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">8.- Comprobante de domicilio de las partes (mínimo dos meses anteriores)</li>
                    </ul>

                    </div>
                                        <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-5" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
            <span class="modal-title">DONACIÓN DE TERRENO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-5');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Último pago de predial</li>
                    <li class="list-group-item">2.- Título o escritura del Inmueble</li>
                    <li class="list-group-item">3.- Acta de matrimonio ó divorcio</li>
                    <li class="list-group-item">4.- Acta de nacimiento del donatario</li>
                    <li class="list-group-item">5.-CURP</li>
                    <li class="list-group-item">6.- Constancia de situación fiscal</li>
                    <li class="list-group-item">7.- Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">8.- Comprobante de domicilio de las partes (mínimo dos meses anteriores)</li>
                    </ul>

                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-6" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
            <span class="modal-title">DONACIÓN DE DEPARTAMENTO EN CONDOMINIO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-6');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Último pago de predial</li>
                    <li class="list-group-item">2.- Título o escritura del Inmueble</li>
                    <li class="list-group-item">3.- Acta de matrimonio ó divorcio</li>
                    <li class="list-group-item">4.- Acta de nacimiento del donatario</li>
                    <li class="list-group-item">5.-CURP</li>
                    <li class="list-group-item">6.- Constancia de situación fiscal</li>
                    <li class="list-group-item">7.- Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">8.- Comprobante de domicilio de las partes (mínimo dos meses anteriores)</li>
                    </ul>

                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-7" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">DONACIÓN DE CASA HABITACIÓN</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-7');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Último pago de predial</li>
                    <li class="list-group-item">2.- Título o escritura del Inmueble</li>
                    <li class="list-group-item">3.- Acta de matrimonio ó divorcio</li>
                    <li class="list-group-item">4.- Acta de nacimiento del donatario</li>
                    <li class="list-group-item">5.-CURP</li>
                    <li class="list-group-item">6.- Constancia de situación fiscal</li>
                    <li class="list-group-item">7.- Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">8.- Comprobante de domicilio de las partes (mínimo dos meses anteriores)</li>
                    </ul>

                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-8" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                <span class="modal-title">DONACIÓN DE LOCAL COMERCIAL</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-8');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Último pago de predial</li>
                    <li class="list-group-item">2.- Último pago de agua y luz</li>
                    <li class="list-group-item">3.- Título o escritura de propiedad</li>
                    <li class="list-group-item">4.- Acta de matrimonio ó divorcio</li>
                    <li class="list-group-item">5.- Acta de nacimiento del donatario</li>
                    <li class="list-group-item">6.- CURP</li>
                    <li class="list-group-item">7.- Constancia de situación fiscal</li>
                    <li class="list-group-item">8.-Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">9- Comprobante de domicilio de las partes (mínimo dos meses anteriores)</li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-9" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">CANCELACIÓN DE HIPOTECA INFONAVIT Y BANCARIA</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-9');" class="close-modalb"> 
                </button>
            </div> 
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1.- Carta de instrucción</li>
                    <li class="list-group-item">2.- Testimonio original que contenga la hipoteca</li>
                    <li class="list-group-item">3.- Identificación oficial vigente de las partes (credencial de elector, pasaporte, cédula profesional).
                    </li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-10" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">CONSTITUCIÓN DE SOCIEDADES</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/sociedades2.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-10');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                                        <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1. Llenar formato <a target="_blank" href="assets/files/generales3.0.pdf">(descargar archivo)</a> </li>
                    <li class="list-group-item">2. Identificación oficial de las partes (credencial de elector, pasaporte, cédula profesional).</li>
                    <li class="list-group-item">3. Cédula del registro federal de contribuyentes (RFC, constancia de situación fiscal) de las partes.</li>
                    <li class="list-group-item">4. CURP de las partes.</li>
                    <li class="list-group-item">5.  Si alguna de las personas que interviene en las operaciones es extranjera, deberá acreditar su legal estancia con forma migratoria o tarjeta de residencia</li>
                    <li class="list-group-item">6. Llenar formato de datos generales para cada persona que intervenga en sociedad <a target="_blank" href="assets/files/generales3.0.pdf">(descargar archivo)</a> </li>
                    <li class="list-group-item">7. En caso de acudir en representación de una persona física o jurídica, presentar poder notarial.</li>
                    </ul>

                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-11" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
            <span class="modal-title">RATIFICACIÓN DE FIRMAS</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-11');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                                            <ul class="list-group">
                    <li class="list-group-item">1. Documento original donde consten las firmas a ratificar</li>
                    <li class="list-group-item">2. Identificación oficial del solicitante (credencial de elector, pasaporte, cédula profesional)</li>
                    <li class="list-group-item">3. CURP de las partes.</li>
                    <li class="list-group-item">4. Llenar formato de datos generales <a target="_blank" href="assets/files/generales3.0.pdf">(descargar archivo)</a> </li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-12" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                    <span class="modal-title">PROTOCOLIZACIÓN DE ACTAS</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-12');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                        <li class="list-group-item">1. Acta de la sociedad debidamente firmada </li>   
                        <li class="list-group-item">2. Escritura donde conste la constitución de la persona jurídica</li>
                        <li class="list-group-item">3. De haber reformas a los estatutos, escrituras donde consten </li>
                        <li class="list-group-item">4. Identificación oficial del delegado (credencial de elector, pasaporte, cédula profesional)</li>
                        <li class="list-group-item">5. Cédula del registro federal de contribuyentes (RFC, constancia de situación fiscal) de las partes</li>
                        <li class="list-group-item">6. CURP del delegado</li>
                        <li class="list-group-item">7. R.F.C. de la persona jurídica</li>
                        <li class="list-group-item">8.  Si alguna de las personas que interviene en las operaciones es extranjera, deberá acreditar su legal estancia con forma migratoria o tarjeta de residencia</li>
                        <li class="list-group-item">9. Llenar formato de datos generales del delegado <a target="_blank" href="assets/files/generales3.0.pdf">(descargar archivo)</a> </li>
                        </ul>

                        </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-13" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                    <span class="modal-title">PODERES. </span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-13');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                        <li class="list-group-item">1. Identificación oficial de poderdante (credencial de elector, pasaporte, cédula profesional)</li>
                        <li class="list-group-item">2. Si es una persona moral el poderdante, se requerirá acta constitutiva  así como la escritura donde constan sus facultades para otorgar poder</li>
                        <li class="list-group-item">3. Si alguna de las personas que interviene en las operaciones es extranjera, deberá acreditar su legal estancia con forma migratoria o tarjeta de residencia</li>
                        <li class="list-group-item">4. Llenar formato de datos generales del poderdante</li>
                        </ul>

                        </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-14" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                    <span class="modal-title">REVOCACIÓN DE PODER</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-14');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                        <li class="list-group-item">1.- Testimonio original del poder actual</li>
                        <li class="list-group-item">2.- Identificación oficial del solicitante (credencial de elector, pasaporte, cédula profesional)</li>
                        <li class="list-group-item">3. CURP del solicitante</li>
                        <li class="list-group-item">4.- Constancia de situación fiscal</li>
                        </ul>
                        </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-15" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                <span class="modal-title">FE DE DERECHOS</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-15');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                    <li class="list-group-item">1. Carta de solicitud al notario, que deberá contener datos de tiempo, lugar y domicilio para llevar a cabo la visita</li>
                    <li class="list-group-item">2. Identificación oficial del solicitante (credencial de elector, pasaporte, cédula profesional)</li>
                    <li class="list-group-item">3. CURP de las partes</li>
                    <li class="list-group-item">4. En caso de actuar en representación de una persona física o jurídica, presentar poder notarial</li>
                    <li class="list-group-item">5. Llenar formato de datos generales <a target="_blank" href="assets/files/generales3.0.pdf">(descargar archivo)</a> </li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-16" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">COPIAS CERTIFICADAS</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-16');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                        <li class="list-group-item">1. Documento original a cotejar</li>
                        <li class="list-group-item">2. Identificación oficial de quien solicite (credencial de elector, pasaporte, cédula profesional)</li>
                    </ul>
                    </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-17" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">CARTA PERMISO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-17');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Todos los documentos deberán ser: ORIGINALES</p>   
                    </div>
                    <div class="row">
                        <ul class="list-group">
                        <li class="list-group-item">1.- Identificación oficial vigente de padre, madre o ambos (credencial de elector, pasaporte, cédula profesional).</li>
                        <li class="list-group-item">2.- Acta de matrimonio ó divorcio</li>
                        <li class="list-group-item">3.- Acta de nacimiento del menor</li>
                        <li class="list-group-item">4.- Pasaporte vigente del menor</li>
                        <li class="list-group-item">5.- Itinerario y boleto de viaje del menor</li>
                        <li class="list-group-item">6.-Identificación de la persona con la que viajará o en su caso, recibirá en el extranjero</li>
                        <li class="list-group-item">7.- CURP del menor y de los comparecientes</li>
                        </ul>
                        </div>
                    <span>NOTA: Con la revisión de los documentos antes mencionados notificaremos si se requiere alguno en particular.</span>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-18" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">ADJUDICACIONES POR REMATE JUDICIAL</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-18');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-19" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">FIDEICOMISOS</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-19');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-20" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">DACIONES DE PAGO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-20');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-21" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">TRANSMISIÓN DE PROPIEDAD EN EJECUCIÓN DE FIDEICOMISO</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-21');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-22" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">APORTACIONES A SOCIEDADES</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/sociedades2.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-22');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-23" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">HIPOTECA</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-23');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-modal-24" class="modal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header ">
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-md-8 ml-auto divtitle">
                        <span class="modal-title">CANCELACIÓN DE HIPOTECA (GRAVAMEN)</span>
                        </div>
                        <div class="col-md-4 ml-auto formatb">
                            <span>DESCARGAR FORMATO 
                                <a target="_blank" href="assets/files/generales3.0.pdf"><img src="assets/img/flecha-hacia-abajo.png" alt="Help"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <button (click)="closeModal('custom-modal-24');" class="close-modalb"> 
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <span class="modal-req">REQUISITOS:</span>
                    </div>
                    <div class="row">
                        <p class="modal-req-sub">Se le notificará en la notaria los documentos que se requieren </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<style>
div#pills-home .row {
  padding: 0px 12% 0px 12%;  
}
#pills-tab .nav-item a{
    color: #bfbdbf;
     font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.7px;
  text-align: center;
 
}
#pills-tab .nav-item a.active{

    background-color: transparent;
    border-bottom: 3px solid #f2e7ac;
     color: #0d0d0d;
  -webkit-clip-path: polygon(var(--left-side) 0, var(--right-side) 0, var(--right-side) 100%, var(--left-side) 100%);
          clip-path: polygon(var(--left-side) 0, var(--right-side) 0, var(--right-side) 100%, var(--left-side) 100%);
  -webkit-transition: .35s ease;
  transition: .35s ease;
}
  button.btnslide{
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;  

  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;  width: 12rem;
  height: auto;
}

button.btn.btnslide .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #f2e7ac;
  border-radius: 1.625rem;
}
button.btn.btnslide .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.btn.btnslide .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.btn.btnslide .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.700rem;
  height: 0.625rem;
  border-top: 0.125rem solid #4e4d59;
  border-right: 0.125rem solid #4e4d59;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
button.btn.btnslide .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  margin: 0 0 0 3rem;
  color: #4e4d59;
 font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 2.1px;
  text-align: center;
}
button.btnslide:hover .circle {
  width: 100%;
}
button.btnslide:hover .circle .icon.arrow {
  background: #4e4d59;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}

#container {
  grid-area: main;
  align-self: center;
  justify-self: center;
}
/* MODAL STYLES
-------------------------------*/


    .jw-modal {
        /* modal container fixed across whole screen */
        display: none;
        position: fixed;
       top: 50%;
        right: 0;
        bottom: 0;
        left: 0;

        /* z-index must be higher than .jw-modal-background */
        z-index: 1000;
        
        /* enables scrolling for tall modals */
        overflow: auto;
}
        .jw-modal-body {
            padding: 20px;
            background: #fff;

            /* margin exposes part of the modal background */
            margin: 40px;
        }
    

    .jw-modal-background {
        /* modal background fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* semi-transparent black  */
        background-color: #000;
        opacity: 0.75;
        
        /* z-index must be below .jw-modal and above everything else  */
        z-index: 900;
    }
}

body.jw-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}
@media only screen and (max-width:1300px) {
div#pills-home .row {
  padding: 0px 3% 0px 3%;  
}
}
</style>
